<template>
  <div class="px-10">
    <div align="center">
      <v-col cols="10">
        <v-progress-linear :value="valueDeterminate" height="30">
          <strong>ตั้งค่าสำเร็จ {{ Math.ceil(valueDeterminate) }}%</strong>
        </v-progress-linear>
      </v-col>
    </div>
    <v-row class="mb-7"
      ><v-col cols="1"></v-col>
      <v-col cols="2"><div class="text-h6">1. ตั้งค่าสาขา</div></v-col>
      <v-col cols="4"
        ><div class="text-h5 primary--text">
          2. เปิดใช้งาน พ.ร.บ./ประกันภัย
        </div></v-col
      >
      <v-col cols="2"><div class="text-h6">3. ตั้งค่าธุรกิจ</div></v-col>
      <!-- <v-col cols="2"><div class="text-h6">4. ตั้งค่าเอกสาร</div></v-col> -->
      <v-col cols="2"><div class="text-h6">4. ราคาสินค้า</div></v-col
      ><v-col cols="1"></v-col>
    </v-row>
    <div align="center" class="text-h5 mb-4 red--text mt-14">
      ติ๊กเลือกบริษัท พ.ร.บ. และประกันภัยที่ร้านท่านขาย
      ถ้าไม่มีเดี๋ยวค่อยไปเพิ่มทีหลัง ในระบบได้
    </div>
    <div class="text-h4 primary--text mb-5 mt-14">2. เปิดใช้งาน พ.ร.บ./ประกันภัย</div>
    <div>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col>
          <v-list-item class="ml-10">
            <v-row>
              <v-col cols="3" v-for="(item, i) in list" :key="i">
                <v-checkbox
                  style="transform: scale(1.1)"
                  :label="item.name"
                  :value="item.is_available"
                  v-model="item.is_available"
                ></v-checkbox>
              </v-col>
            </v-row> </v-list-item
        ></v-col>
      </v-row>
    </div>
    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()">
          ย้อนกลับ
        </v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          ถัดไป
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    checkbox: [],
    loading: false,
    valueDeterminate: 40,
    branch_id: 0,
    setup_wizard: 0,
    listSelected: [],
    subcategory_array: '',
    list: [],
  }),
  created() {
    this.branch_id = this.$route.params.id;
    this.getData();
    this.getCheck();
  },
  methods: {
    async getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true,}
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          this.setup_wizard = res.result;
          console.log(res.result);
        })
        .catch((err) => {
          console.log('err', err);
        });
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true,}
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/get_wizard2`, body)
        .then((res) => {
          this.list = res.result;
          console.log(this.list);
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async save() {
      let subcategory_array = [];
      this.listSelected.forEach((element) => {
        subcategory_array.push(element._id);
      });

      let fill = {
        subcategory_array: this.list,
        branch_id: this.$route.params.id,
        setup_wizard: this.setup_wizard,
      };
      if (fill.setup_wizard != 2) {
        fill.setup_wizard = this.setup_wizard;
      } else {
        fill.setup_wizard = 3;
      }
      console.log(fill);
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/setup_wizard2`, body)
        .then((res) => {
          this.$router.push({
            name: 'setup-business',
            params: { id: this.$route.params.id },
          });
        })
        .catch((err) => {
          this.$alertServerError({ title: err.error_message });
        });
    },
    async close() {
      let fill = {
        branch_id: this.$route.params.id,
        setup_wizard: 1,
      };
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/set_setup_wizard`, body)
        .then((res) => {
          this.$router.push({
            name: 'setup-business-branch-update',
          });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
  },
};
</script>
